import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Palette } from '@styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckMoving, faCarSide, faCaravan, faTruckPickup, faTrailer, faTruck } from '@fortawesome/free-solid-svg-icons'

export const keyFrameExampleOne = keyframes`
  0% {
    opacity: 0;
    left: -20vw;
  }
  1% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 120vw;
  }
`
export const keyFrameExampleTwo = keyframes`
  0% {
    opacity: 0;
    left: -6vw;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 105vw;
  }
`
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${Palette.white};
  color: ${Palette.black};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
`;

const TruckContainer = styled.div`
  position: relative;
  height: 32px;
  display: flex;
  margin-top: 16px;
  border-bottom: 1px solid black;
  width: 100vw;
`

const Icon = styled(FontAwesomeIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`
const TruckIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${keyFrameExampleOne} 15s linear 0s infinite;
  position: absolute;
`
const TruckTrailerWrapper = styled.div`
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  animation: ${keyFrameExampleOne} 15s linear 3.75s infinite;
`;

const TruckCaravanWrapper = styled(TruckTrailerWrapper)`
  animation: ${keyFrameExampleOne} 15s linear 7.5s infinite;
`;

const CarIcon = styled(TruckIcon)`
  opacity: 0;
  animation: ${keyFrameExampleOne} 15s linear 11.25s infinite;
`;


const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 8px;
`;

export const Landing = () => {
  const truckCaravan = (
    <TruckCaravanWrapper>
      <FontAwesomeIcon icon={faCaravan} color={Palette.black} />
      <FontAwesomeIcon style={{marginRight: '10px'}} icon={faTruckPickup} color={Palette.black} />
    </TruckCaravanWrapper>
  );

  const truckTrailer = (
    <TruckTrailerWrapper>
      <FontAwesomeIcon CaravanIcon icon={faTrailer} color={Palette.black} />
      <FontAwesomeIcon CarIcon style={{marginRight: '10px'}} icon={faTruckPickup} color={Palette.black} />
    </TruckTrailerWrapper>
  );

  return (
    <Container>
      DNR Vape
      <Text> Coming Soon </Text>
      <TruckContainer>
        <TruckIcon icon={faTruckMoving} color={Palette.black} />
        {truckCaravan}
        {truckTrailer}
        <CarIcon icon={faCarSide} color={Palette.black} />
      </TruckContainer>
    </Container>
  );
};

export default Landing;
